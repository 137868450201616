import { quizConnectQuery } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { useThree } from "@react-three/fiber";
import { useMemo } from "react";
import scenesData from "../../../question-generator/data/scenes.json";
import { FrozenModelPreview } from "./FrozenModelPreview";

export const CameraAngleTestInner = () => {
  const modelId = "ea516e48-496d-4f1a-8f83-0c5c5116a612";

  const getModelQuery = useSuspenseQuery(quizConnectQuery.getModel, {
    id: modelId,
  });
  const frozenModelId = getModelQuery.data?.latestFrozenModelId;
  const getFrozenModelQuery = useSuspenseQuery(
    quizConnectQuery.getFrozenModel,
    { id: frozenModelId },
  );
  const manifest = getFrozenModelQuery.data?.manifest;

  const filteredMeshIds = useMemo(() => {
    // Replace musclesFoot1 with the scene name you want to show from scenes.json
    const targetMeshes =
      scenesData["scenes"]["prometheus-98-a"]["visibleMeshNames"];
    const filteredMeshIds = (manifest?.meshes ?? [])
      .filter((mesh) => targetMeshes.includes(mesh.gltfMeshName))
      .map((mesh) => mesh.id);
    return filteredMeshIds;
  }, []);

  const camera = useThree(({ camera }) => camera);
  console.log("DEBUG camera", camera.position);

  return (
    <FrozenModelPreview
      frozenModelId={frozenModelId}
      backgroundColor={0xffffff}
      visibleMeshIds={filteredMeshIds}
    />
  );
};
