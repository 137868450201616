import { quizPb } from "@augmedi/proto-gen";
import { Link } from "wouter";
import * as classes from "./AppNavLinks.module.css";

interface Props {
  permissions: quizPb.Permission[];
  onNavLinkClick?: () => void;
}

export const AppNavLinks = ({ permissions, onNavLinkClick }: Props) => {
  const hasPermission = (permission: quizPb.Permission): boolean => {
    return permissions.includes(permission);
  };

  return (
    <>
      {hasPermission(quizPb.Permission.USE_ANATOMY_QUIZ) && (
        <Link
          className={classes.menuButton}
          to="/quiz/chapters"
          onClick={onNavLinkClick}
        >
          Quiz
        </Link>
      )}
      {hasPermission(quizPb.Permission.READ_ATLAS_AUGMEDI_INTERNAL) ? (
        <Link
          className={classes.menuButton}
          to="/atlas"
          onClick={onNavLinkClick}
        >
          Structures
        </Link>
      ) : (
        <div className={classes.menuButton}>Atlas (Coming Soon)</div>
      )}
      {hasPermission(quizPb.Permission.USE_ANATOMY_QUIZ) && (
        <Link
          className={classes.menuButton}
          to="/stats"
          onClick={onNavLinkClick}
        >
          Progress
        </Link>
      )}
      {hasPermission(quizPb.Permission.EDIT_MODELS) && (
        <Link
          className={classes.menuButton}
          to="/models"
          onClick={onNavLinkClick}
        >
          Models
        </Link>
      )}
      {hasPermission(quizPb.Permission.EDIT_APPROACHES) && (
        <Link
          className={classes.menuButton}
          to="/approaches"
          onClick={onNavLinkClick}
        >
          Approaches
        </Link>
      )}
      {hasPermission(quizPb.Permission.ANNOTATE) && (
        <Link
          className={classes.menuButton}
          to="/annotator"
          onClick={onNavLinkClick}
        >
          Annotator
        </Link>
      )}
      {hasPermission(quizPb.Permission.READ_USERS) && (
        <Link
          className={classes.menuButton}
          to="/users"
          onClick={onNavLinkClick}
        >
          Users
        </Link>
      )}
      {hasPermission(quizPb.Permission.QR_CODE) && (
        <Link
          className={classes.menuButton}
          to="/auth-qr"
          onClick={onNavLinkClick}
        >
          AR/VR login
        </Link>
      )}
    </>
  );
};
