/* eslint react/no-unescaped-entities: off, @typescript-eslint/no-unused-vars: off */
import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import type { PlainMessage } from "@bufbuild/protobuf";
import {
  createConnectQueryKey,
  useMutation,
  useSuspenseQuery,
} from "@connectrpc/connect-query";
import {
  Button,
  Container,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useInterval } from "@mantine/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useParams } from "wouter";
import { useNavigationLockAndBeforeUnload } from "../logic/navigation-lock.js";
import { DraggableList } from "./DraggableList.js";

interface ApproachEdits {
  name?: string;
  stepOrder?: string[];
}

function reduceLocalEdits(
  approachFromServer: PlainMessage<quizPb.Approach>,
  localEdits: ApproachEdits,
): ApproachEdits | undefined {
  if (localEdits.name === approachFromServer.name) {
    localEdits = { ...localEdits, name: undefined };
  }
  if (
    localEdits.stepOrder &&
    localEdits.stepOrder.length === approachFromServer.steps.length &&
    localEdits.stepOrder.every(
      (stepId, i) => stepId === approachFromServer.steps[i].id,
    )
  ) {
    localEdits = { ...localEdits, stepOrder: undefined };
  }

  return Object.values(localEdits).every((value) => value === undefined)
    ? undefined
    : localEdits;
}

export const ApproachDraftPage = () => {
  const [_location, navigate] = useLocation();
  const { approachId } = useParams<{ approachId: string }>();

  const queryClient = useQueryClient();

  const whoAmIQuery = useSuspenseQuery(quizConnectQuery.whoAmI);
  const canFreezeApproaches = whoAmIQuery.data.permissions.includes(
    quizPb.Permission.FREEZE_APPROACHES,
  );

  const getApproachQuery = useSuspenseQuery(quizConnectQuery.getApproach, {
    id: approachId,
  });
  const approachFromServer = getApproachQuery.data!;

  const [localEdits, setLocalEdits] = useState<ApproachEdits>();
  const approach = useMemo(
    (): PlainMessage<quizPb.Approach> => ({
      ...approachFromServer,
      name: localEdits?.name ?? approachFromServer.name,
      steps: localEdits?.stepOrder
        ? localEdits.stepOrder.map(
            (stepId) =>
              approachFromServer.steps.find((step) => step.id === stepId) ??
              new quizPb.ApproachStep({ id: stepId, title: "(deleted step)" }),
          )
        : approachFromServer.steps,
    }),
    [approachFromServer, localEdits],
  );
  useEffect(() => {
    if (!localEdits) {
      return;
    }
    const newLocalEdits = reduceLocalEdits(approachFromServer, localEdits);
    if (newLocalEdits !== localEdits) {
      setLocalEdits(newLocalEdits);
    }
  }, [approachFromServer, localEdits]);

  const updateApproachMutation = useMutation(quizConnectQuery.updateApproach, {
    onSuccess: async (res) => {
      // TODO centralize invalidation
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(quizConnectQuery.listApproaches),
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(quizConnectQuery.getApproach, {
            id: res.id,
          }),
        }),
      ]);
    },
  });
  const saveLocalEdits = () => {
    if (!localEdits) {
      return;
    }
    updateApproachMutation.mutate({
      id: approach.id,
      name: localEdits.name ?? approach.name,
      stepOrder: localEdits.stepOrder ?? approach.steps.map((step) => step.id),
    });
  };

  const autosaveIntervalHandlerRef = useRef<() => void>();
  useEffect(() => {
    autosaveIntervalHandlerRef.current = saveLocalEdits;
  });
  const autosaveInterval = useInterval(
    () => autosaveIntervalHandlerRef.current?.(),
    1000,
  );
  useEffect(() => {
    autosaveInterval.start();
    return () => autosaveInterval.stop();
  }, []);

  const anythingSaving = updateApproachMutation.isPending;
  const anythingDirty = !!localEdits;
  useNavigationLockAndBeforeUnload(anythingDirty);

  let userVisibleSaveState: "saving" | "saved" | "error";
  if (!anythingDirty) {
    userVisibleSaveState = "saved";
  } else if (anythingSaving) {
    userVisibleSaveState = "saving";
  } else if (updateApproachMutation.isError) {
    userVisibleSaveState = "error";
  } else {
    // We're not saving yet, but we will be soon because of the autosave timer.
    userVisibleSaveState = "saving";
  }
  const userVisibleSaveStateLabels: {
    [K in typeof userVisibleSaveState]: string;
  } = {
    saved: "All changes saved",
    saving: "Saving...",
    error: "Failed to save changes",
  };

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const deleteApproachMutation = useMutation(quizConnectQuery.deleteApproach, {
    onSuccess: async (_res, req) => {
      setDeleteModalOpen(false);
      navigate("/approaches");
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(quizConnectQuery.listApproaches),
      });
      if (req.id) {
        await queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(quizConnectQuery.getApproach, {
            id: req.id,
          }),
        });
      }
    },
  });

  const createApproachStepMutation = useMutation(
    quizConnectQuery.createApproachStep,
    {
      onSuccess: async (res, req) => {
        const approachId = req.approachId;
        const approachStepId = res.id;

        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: createConnectQueryKey(quizConnectQuery.listApproaches),
          }),
          queryClient.invalidateQueries({
            queryKey: createConnectQueryKey(quizConnectQuery.getApproach, {
              id: approachId,
            }),
          }),
        ]);

        navigate(`/approaches/${approachId}/draft/steps/${approachStepId}`);
      },
    },
  );

  const freezeApproachMutation = useMutation(quizConnectQuery.freezeApproach, {
    onSuccess: async (_res, req) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(quizConnectQuery.listApproaches),
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(quizConnectQuery.getApproach, {
            id: req.id,
          }),
        }),
      ]);
      navigate(`/approaches/${req.id}/frozen`);
    },
  });

  return (
    <>
      <Container py="md">
        <Stack>
          <Group justify="space-between" align="center">
            <Title
              order={1}
              fs={approach.name.trim() ? undefined : "italic"}
              c={approach.name.trim() ? undefined : "dimmed"}
            >
              {approach.name.trim() || "(untitled approach)"}
            </Title>
            <Group>
              <Text fs="italic" c="dimmed">
                {userVisibleSaveStateLabels[userVisibleSaveState]}
              </Text>
              {approach.latestFrozenApproachId ? (
                ""
              ) : (
                <Button color="red" onClick={() => setDeleteModalOpen(true)}>
                  Delete approach
                </Button>
              )}
            </Group>
          </Group>
          <TextInput
            label="Name"
            description="The name of the approach as shown to users"
            placeholder="Name"
            value={approach.name}
            onChange={(ev) =>
              setLocalEdits((localEdits) => ({
                ...localEdits,
                name: ev.target.value,
              }))
            }
          />
          <Title order={2}>Steps</Title>
          <DraggableList
            items={approach.steps.map((s) => ({
              id: s.id,
              label: s.title,
              linkTo: `/approaches/${approach.id}/draft/steps/${s.id}`,
            }))}
            onReorder={(idsInNewOrder) =>
              setLocalEdits((localEdits) => ({
                ...localEdits,
                stepOrder: idsInNewOrder,
              }))
            }
          />
          <Group>
            <Button
              onClick={() =>
                createApproachStepMutation.mutate({ approachId: approach.id })
              }
            >
              Add step
            </Button>
          </Group>
          <Title order={2}>Frozen version</Title>
          {approach.latestFrozenApproachId ? (
            <Link to={`/approaches/${approachId}/frozen`}>
              Latest frozen version
            </Link>
          ) : (
            <Text>This approach has no frozen version</Text>
          )}
          {canFreezeApproaches && (
            <Group>
              <Button
                onClick={() =>
                  freezeApproachMutation.mutate({ id: approach.id })
                }
              >
                Freeze approach
              </Button>
            </Group>
          )}
        </Stack>
      </Container>
      <Modal
        opened={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        title="Delete approach?"
      >
        <Stack>
          <Text>
            Are you sure you want to delete the approach "{approach.name}"?
          </Text>
          <Button
            color="red"
            onClick={() => deleteApproachMutation.mutate({ id: approach.id })}
          >
            Delete {approach.name}
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
