import { quizPb } from "@augmedi/proto-gen";
import { isTruthy } from "@augmedi/type-utils";
import type { PlainMessage } from "@bufbuild/protobuf";
import { Checkbox, Flex, Group, Stack, Text, Title } from "@mantine/core";
import { Fragment, useRef } from "react";
import { colorConstants } from "../color-constants";
import { useIsMobileWithCanvas } from "../logic/app-layout";
import {
  splitHighlightsIntoChannels,
  type HighlightedId,
} from "../logic/highlights";
import { FrozenModelPreview } from "./FrozenModelPreview";
import { LogoImage } from "./LogoImage";
import ModelViewerUi from "./ModelViewerUi";
import { OurCanvas } from "./OurCanvas";
import { QuizLayout } from "./QuizLayout";
import type { SharedModelPreviewStuffRef } from "./SharedModelPreviewStuff";

interface Props {
  introductionGroup: PlainMessage<quizPb.IntroductionGroup>;
  selectedItemIds: string[];
  seenItemIds: string[];
  onClick?: (itemIds: string[]) => void;
  controlsPanel?: JSX.Element;
}

function highlightedIdsFromIntroductionGroup(
  introductionGroup: PlainMessage<quizPb.IntroductionGroup>,
  selectedItemIds: string[],
  seenItemIds: string[],
): HighlightedId[] {
  const highlightedIdsFromSelected = new Set(
    introductionGroup.items
      .filter((item) => selectedItemIds.includes(item.id))
      .flatMap((item) => item.highlightedLabelIds),
  );
  const seenLabelIds = new Set(
    introductionGroup.items
      .filter((item) => seenItemIds.includes(item.id))
      .flatMap((item) => item.highlightedLabelIds),
  );
  const secondaryLabelIds = new Set(
    introductionGroup.items
      .filter((item) => selectedItemIds.includes(item.id))
      .flatMap((item) => item.secondaryLabelIds),
  );
  const highlightedIds = new Set([
    ...introductionGroup.items.flatMap((item) => item.highlightedLabelIds),
    ...introductionGroup.ambientHighlightedLabelIds,
    ...secondaryLabelIds,
  ]);

  return [...highlightedIds].map((id) => ({
    id,
    color: highlightedIdsFromSelected.has(id)
      ? colorConstants.introductionGroupSelectedColor
      : seenLabelIds.has(id)
        ? colorConstants.introductionGroupSeenColor
        : secondaryLabelIds.has(id)
          ? colorConstants.secondaryColor
          : colorConstants.ambientHighlightColor,
    pulse:
      !highlightedIdsFromSelected.has(id) &&
      !seenLabelIds.has(id) &&
      !secondaryLabelIds.has(id),
  }));
}

export const IntroductionGroupViewer = ({
  introductionGroup,
  selectedItemIds,
  seenItemIds,
  onClick,
  controlsPanel,
}: Props) => {
  const sharedStuffRef = useRef<SharedModelPreviewStuffRef>(null);

  const isMobileWithCanvas = useIsMobileWithCanvas();

  const highlightedIds = highlightedIdsFromIntroductionGroup(
    introductionGroup,
    selectedItemIds,
    seenItemIds,
  );

  const { visibleLabelIds, settingsByChannel } =
    splitHighlightsIntoChannels(highlightedIds);

  const handleItemClick = (labelIds: string[]) =>
    onClick?.(
      introductionGroup.items
        .filter((item) =>
          item.highlightedLabelIds.some((id) => labelIds.includes(id)),
        )
        .map((item) => item.id),
    );

  // convert initailCameraPosition to array
  const initialCameraPosition = introductionGroup.initialCameraPosition && [
    introductionGroup.initialCameraPosition.x,
    introductionGroup.initialCameraPosition.y,
    introductionGroup.initialCameraPosition.z,
  ];

  const canvasContent = (
    <ModelViewerUi onResetCamera={() => sharedStuffRef.current?.resetCamera()}>
      <OurCanvas>
        <FrozenModelPreview
          ref={sharedStuffRef}
          backgroundColor={0xffffff}
          frozenModelId={introductionGroup.frozenModelId}
          visibleLabelIdsPerChannel={visibleLabelIds}
          visibleMeshIds={introductionGroup.visibleMeshIds}
          initialCameraPosition={initialCameraPosition}
          settingsByChannel={settingsByChannel}
          onClick={handleItemClick}
        />
      </OurCanvas>
    </ModelViewerUi>
  );

  const clickStructureMessageHTML = !isMobileWithCanvas ? (
    <Text>
      Click a structure to see its name here. You have to click each structure
      before continuing.
    </Text>
  ) : null;

  const selectedItems = selectedItemIds
    .map((id) => introductionGroup.items.find((item) => item.id === id))
    .filter(isTruthy);

  const selectedStructureHTML = selectedItems.some(
    (item) => item.legacyDescription,
  ) ? (
    <Stack mb="xs">
      {selectedItems.map((item) => (
        <Fragment key={item.id}>
          {!isMobileWithCanvas && (
            <Title order={4}>{item.text ?? "Unknown item"}</Title>
          )}
          {item.legacyDescription && (
            <Text style={{ whiteSpace: "pre-wrap" }}>
              {item.legacyDescription}
            </Text>
          )}
        </Fragment>
      ))}
    </Stack>
  ) : null;

  const structureInfoContent = (
    <>
      <Flex
        p={isMobileWithCanvas ? "sm" : "md"}
        style={{ flexDirection: "column" }}
      >
        {/* Currently Selected Info */}
        {!isMobileWithCanvas && (
          <Text size="sm" c="dimmed">
            Currently selected
          </Text>
        )}
        {selectedItems.length
          ? selectedStructureHTML
          : clickStructureMessageHTML}

        {/* Structure List */}
        {!!introductionGroup.items.length && (
          <>
            {!isMobileWithCanvas && (
              <Text
                size="sm"
                c="dimmed"
                mt={isMobileWithCanvas ? "xs" : "md"}
                mb="xs"
              >
                Structures
              </Text>
            )}
            <Stack gap="xs">
              {introductionGroup.items.map((item) => (
                <Checkbox
                  key={item.id}
                  label={<Text size="sm">{item.text ?? "Unknown item"}</Text>}
                  onClick={() => onClick?.([item.id])}
                  style={{ "--mantine-cursor-type": "pointer" }}
                  color={
                    selectedItemIds.includes(item.id)
                      ? colorConstants.introductionGroupSelectedColorMantine
                      : seenItemIds.includes(item.id)
                        ? colorConstants.introductionGroupSeenColorMantine
                        : colorConstants.introductionGroupRemainingColor
                  }
                  indeterminate={!seenItemIds.includes(item.id)}
                  checked
                  readOnly
                />
              ))}
            </Stack>
          </>
        )}

        {/* Controls */}
        {controlsPanel}
      </Flex>
    </>
  );

  return (
    <QuizLayout
      title={introductionGroup.text}
      canvasContent={canvasContent}
      structureInfoContent={structureInfoContent}
      canvasExtrasSmall={
        <Group
          style={{
            position: "absolute",
            left: "1rem",
            bottom: "1rem",
            opacity: 0.5,
          }}
        >
          <LogoImage />
        </Group>
      }
    />
  );
};
